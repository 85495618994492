import React from 'react'

const HomeSolutions = () => {

    return (
        <div className='solutions-block' id='products'>
            <div className="container solutions-container">
                <div className='d-flex align-items-center gap-3'>
                    <p className="title mb-0">Наши&nbsp;решения</p>
                    <hr style={{ border: '1px solid #00B2D9', opacity: 1, width: '100%' }} />
                </div>
                <div className="pt-5 mb-5">
                    <p>
                        <span className="comname">ARCANE DEVLAB</span> специализируется на разработке инновационных IT-решений, которые помогают компаниям повышать эффективность и конкурентоспособность. Мы создаем высокотехнологичные веб-приложения, мобильные приложения, телеграм-боты и другие программные продукты, адаптированные под уникальные потребности каждого клиента.
                    </p>
                    <p>
                        Мы гордимся своими проектами, которые охватывают различные отрасли, включая транспорт и логистику, медиа и развлечения, автоматизацию бизнеса и многое другое.
                    </p>
                </div>

                <div className="row sol-row pt-5 mb-4">
                    <div className="col sol-col">
                        <p className="solution-name">ADL Hyperion</p>
                    </div>
                    <div className="col sol-col">
                        <p>
                            <span className="comname">ARCANE DEVLAB</span> разработала ADL Hyperion — передовую систему автоматизации бизнеса, интегрирующую передовые технологии для оптимизации процессов управления проектами, коммуникацией и аналитикой. <br /><br /> Гибкость и адаптивность ADL Hyperion позволяют ей эффективно взаимодействовать с различными отраслями и интегрироваться с существующими IT-инфраструктурами, обеспечивая высокую производительность и экономию операционных затрат.
                        </p>
                    </div>
                </div>
                <div className="row sol-row pt-5 mb-5">
                    <div className="col sol-col">
                        <p className='pt-4'>
                            ADL Assistant — ваш персональный помощник в управлении задачами, расписанием и бюджетом. Это мобильное приложение от ARCANE DEVLAB объединяет функционал для эффективного планирования и контроля всех аспектов вашей работы и личных дел.
                        </p>
                        <p>
                            С ADL Assistant вы можете легко создавать задачи, устанавливать приоритеты и сроки выполнения, а также отслеживать их выполнение в режиме реального времени. Приложение предлагает интеграцию с календарем для удобного планирования встреч и событий.
                        </p>
                        <p>
                            Кроме того, ADL Assistant включает инструменты для контроля бюджета, позволяя вам следить за расходами и доходами, создавать бюджетные планы и отслеживать финансовое состояние.
                        </p>
                        {/* <p>
                            Наше приложение разработано с акцентом на удобство использования и персонализацию, чтобы удовлетворить все ваши потребности в эффективном управлении временем и ресурсами.
                        </p> */}
                    </div>
                    <div className="col sol-col">
                        <p className="solution-name">ADL Assistant</p>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    {/* <button className='prime-link'>
                        Все продукты
                    </button> */}
                </div>
            </div>
        </div>
    )
}

export default HomeSolutions