import React from 'react'

const LetterN = () => {
    return (
        <svg className='top-letter' viewBox="0 0 514 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.400003 560V-3.8147e-05H107.6L438 403.2H386V-3.8147e-05H514V560H407.6L76.4 156.8H128.4V560H0.400003Z" fill="url(#paint0_linear_5_94)" />
            <defs>
                <linearGradient id="paint0_linear_5_94" x1="148.259" y1="169.5" x2="442.337" y2="417.491" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B839D" />
                    <stop offset="1" stop-color="#0E3654" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LetterN