import React from 'react'

const LetterE = () => {
    return (
        <svg className='top-letter' style={{ maxHeight: '84vh' }} viewBox="0 0 434 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M119.6 225.6H389.2V326.4H119.6V225.6ZM129.2 456H434V560H0.400003V-3.8147e-05H423.6V104H129.2V456Z" fill="url(#paint0_linear_5_98)" />
            <defs>
                <linearGradient id="paint0_linear_5_98" x1="111.832" y1="169.5" x2="392.158" y2="365.704" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B839D" />
                    <stop offset="1" stop-color="#0E3654" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LetterE