import React from 'react'

const LetterR = () => {
    return (
        <svg className='top-letter' viewBox="0 0 497 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.400003 560V-3.8147e-05H242.8C292.933 -3.8147e-05 336.133 8.26663 372.4 24.8C408.667 40.8 436.667 64 456.4 94.4C476.133 124.8 486 161.067 486 203.2C486 244.8 476.133 280.8 456.4 311.2C436.667 341.067 408.667 364 372.4 380C336.133 396 292.933 404 242.8 404H72.4L130 347.2V560H0.400003ZM356.4 560L216.4 356.8H354.8L496.4 560H356.4ZM130 361.6L72.4 300.8H235.6C275.6 300.8 305.467 292.267 325.2 275.2C344.933 257.6 354.8 233.6 354.8 203.2C354.8 172.267 344.933 148.267 325.2 131.2C305.467 114.133 275.6 105.6 235.6 105.6H72.4L130 44V361.6Z" fill="url(#paint0_linear_5_61)" />
            <defs>
                <linearGradient id="paint0_linear_5_61" x1="129.052" y1="169.5" x2="417.281" y2="390.769" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B839D" />
                    <stop offset="1" stop-color="#0E3654" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LetterR