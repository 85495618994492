import React from 'react'

const LetterC = () => {

    return (
        <svg className='top-letter' viewBox="0 0 535 580" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M303.4 579.6C260.2 579.6 219.933 572.667 182.6 558.8C145.8 544.4 113.8 524.133 86.6 498C59.4 471.867 38.0667 441.2 22.6 406C7.66667 370.8 0.200001 332.133 0.200001 290C0.200001 247.867 7.66667 209.2 22.6 174C38.0667 138.8 59.4 108.133 86.6 82C114.333 55.8666 146.6 35.8666 183.4 22C220.2 7.59998 260.467 0.39999 304.2 0.39999C352.733 0.39999 396.467 8.93332 435.4 26C474.867 42.5333 507.933 67.0666 534.6 99.6L451.4 176.4C432.2 154.533 410.867 138.267 387.4 127.6C363.933 116.4 338.333 110.8 310.6 110.8C284.467 110.8 260.467 115.067 238.6 123.6C216.733 132.133 197.8 144.4 181.8 160.4C165.8 176.4 153.267 195.333 144.2 217.2C135.667 239.067 131.4 263.333 131.4 290C131.4 316.667 135.667 340.933 144.2 362.8C153.267 384.667 165.8 403.6 181.8 419.6C197.8 435.6 216.733 447.867 238.6 456.4C260.467 464.933 284.467 469.2 310.6 469.2C338.333 469.2 363.933 463.867 387.4 453.2C410.867 442 432.2 425.2 451.4 402.8L534.6 479.6C507.933 512.133 474.867 536.933 435.4 554C396.467 571.067 352.467 579.6 303.4 579.6Z" fill="url(#paint0_linear_5_68)" />
            <defs>
                <linearGradient id="paint0_linear_5_68" x1="163.39" y1="179.5" x2="451.364" y2="399.823" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0B839D" />
                    <stop offset="1" stop-color="#0E3654" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LetterC