import React from 'react'

const LetterA = () => {
    return (
        <svg className='top-letter' viewBox="0 0 629 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.8 560L250.4 -3.8147e-05H378.4L628.8 560H492.8L288 65.6H339.2L133.6 560H0.8ZM125.6 440L160 341.6H448L483.2 440H125.6Z" fill="url(#paint0_linear_5_49)" />
            <defs>
                <linearGradient id="paint0_linear_5_49" x1="211" y1="169.5" x2="502" y2="402" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0B839D" />
                    <stop offset="1" stopColor="#0E3654" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default LetterA