import React from 'react'

import { SiTelegram, SiInstagram, SiYoutube } from "react-icons/si";


const HomeContact = () => {

    return (
        <div className='contact-block' id='contact'>
            <div className="container">
                <div className="d-flex flex-column align-items-center gap-2">
                    <p className="title">Свяжитесь с нами</p>
                    <a className='cont-link' href='mailto:arcanedevlab@gmail.com' target='_blank'>Email: arcanedevlab@gmail.com</a>
                    <a className='cont-link' href='https://t.me/arcanedevlab' target='_blank'>Telegram: @arcanedevlab</a>
                    <br />
                    {/* <p className="title">Менеджер по работе с партнерами:</p>
                    <a className='cont-link' href='tel:79859261717' >Тел.: +7-985-926-1717</a>
                    <a className='cont-link' href='https://t.me/djamilya_omarova15' target='_blank'>Telegram: Мила</a>
                    <br /> */}
                    <p className="title">Мы в соцсетях</p>
                    <div className="d-flex justify-content-center gap-3">
                        <a className='con-link-icon' style={{ marginRight: '16px' }} href="https://t.me/arcanedevelopment"><SiTelegram /></a>
                        <a className='con-link-icon' href="https://instagram.com/arcanedevlab"><SiInstagram /></a>
                    </div>
                    {/* <a className='con-link-icon' href="#"><SiYoutube /></a> */}
                </div>
            </div>
        </div>
    )
}

export default HomeContact